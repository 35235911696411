import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Direction } from './shared/models/direction';

import { PaisIso } from './shared/models/pais-iso';

import { environment } from '../environments/environment';


/**
 * Servicio que almacena toda la información global a toda la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  /** Versión de la web */
  public version: string;

  /** Información identificativa del usuario */
  public guid = '';
  public usuarioId: number;
  public userInfo: any;
  public userinfo2: JSON;
  public tercero: string;
  public terceroId: number;
  public adminId: number;
  public selectedLanguage: string;

  

  /** Token de acceso de sesión */
  public token: string;

  /** URL del API */
  public url: string;

  /** URL del endpoint auth del API */
  public auth: string;
  /** URL del endpoint file del API */
  public uploadFileUrl: string;

  /** URL de la carpeta Temporal */
  public rutaCarpeta: string;

  public paisesIso: PaisIso[] =  [];
  public codigoTlfList: any[] = [];

  public directionsList: Direction[] = [];
  public direccionFacturacion: Direction;
  public direccionEntrega: Direction;

  /** Paginación de envios */
  public paginaEnvios: number;
  public limitePagina: number;

  public nombreUsuario: string;
  public nombreCompleto: string;
  public agencia = 'AGENCIA';
  public esAgencia = false;

  constructor(
    public translate: TranslateService,
    public http: HttpClient
  ) {

    this.version = '1.0.0';

    //direcciones api local
    // this.url = 'https://api.2bsig.com:' + environment.PORT + '/api/';
    // this.auth = 'https://api.2bsig.com:' + environment.PORT + '/api/Auth/';
 // this.uploadFileUrl = 'https://api.2bsig.com:' + environment.PORT + '/api/Doc/';
//  this.rutaCarpeta = 'https://api.2bsig.com:' + environment.PORT + '/Temporal/';


    /**Direcciones API nube - Test */
    //  this.url = 'https://test-2bsigapi.azurewebsites.net/api/';
    //  this.auth = 'https://test-2bsigapi.azurewebsites.net/api/Auth/';
    //  this.uploadFileUrl = 'https://test-2bsigapi.azurewebsites.net/api/Doc/';

    /**Direcciones API nube - Producción */
    this.url = 'https://2bsigapi.azurewebsites.net/api/';
    this.auth = 'https://2bsigapi.azurewebsites.net/api/Auth/';
    this.uploadFileUrl = 'https://2bsigapi.azurewebsites.net/api/Doc/';

    //Contenedores de versión Cloud
    this.rutaCarpeta = 'https://2bsig.blob.core.windows.net/docs';




    const user = localStorage.getItem('userinfo');
    this.userInfo = JSON.parse(user);

    this.token = '1234';
    this.selectedLanguage = 'es';
    translate.setDefaultLang('es');

    this.directionsList = null;

    this.paginaEnvios = 0;
    this.limitePagina = 50;
  }

  getPaises() {
    const valores = {};

    const body = {
      valores
    };

    const token = localStorage.getItem('token');

    const url = this.url + 'PaisISO/' + 'recuperar';

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Methods', 'GET, POST, PUT, PATCH, POST, DELETE, OPTIONS')
      .set('content-type', 'application/json')
      .set('accept', 'text/plain')
      .set('Authorization', this.token)
      .set('Referido', 'Web');

    this.http.post<any>(url, body, { headers }).subscribe(response => {
      response.paises.forEach(p => {
        const newPais: PaisIso = {
          nombreES: p.NombreES,
          nombreEN: p.NombreEN,
          nombreFR: p.NombreFR,
          iso2: p.iso2,
          iso3: p.iso3,
          codigotelefono: p.codigotelefono
        };
        this.paisesIso.push(newPais);

        if (p.codigotelefono !== '') {
          const tlf = {
            pais: p.NombreES,
            tlf: p.codigotelefono
          };
          this.codigoTlfList.push(tlf);
        }
      });
    });
  }

  setDirecciones(directionList) {
   // console.log(directionList)
    this.directionsList = directionList;
  }

  getDirecciones() {
   // console.log(this.directionsList)
    return this.directionsList;
  }


  getSelectedDirections() {
    const guid = this.userInfo.guid;
    const token = localStorage.getItem('token');
    const url = this.url;
    //const token = localStorage.getItem('token');

    const valores = {
      guid
    };

    const body = {
      valores
    };

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Methods', 'GET, POST, PUT, PATCH, POST, DELETE, OPTIONS')
      .set('content-type', 'application/json')
      .set('accept', 'text/plain')
      .set('Authorization', this.token)
      .set('Referido', 'Web');

    this.http.post<any>(url, body, { headers }).subscribe(response => {
      const facturation = response.Direcciones[0].facturacion[0] ? response.Direcciones[0].facturacion[0] : null;
      const delivery = response.Direcciones[0].entrega[0] ? response.Direcciones[0].entrega[0] : null;

      if (facturation != null) {
        this.direccionFacturacion = {
          direccionId: facturation.direccionId,
          direccionnombre: facturation.direccionnombre,
          direccion: facturation.direccion,
          direccion1: facturation.direccion1,
          descripcioninterna: facturation.descripcioninterna,
          codpostal: facturation.codpostal,
          poblacion: facturation.poblacion,
          provinciaestado: facturation.provinciaestado,
          paisISO2: facturation.paisISO2,
          observacionesdireccion: facturation.observacionesdireccion,
          expanded: false
        };
      }

      if (delivery != null) {
        this.direccionEntrega = {
          direccionId: delivery.direccionId,
          direccionnombre: delivery.direccionnombre,
          direccion: delivery.direccion,
          direccion1: delivery.direccion1,
          descripcioninterna: delivery.descripcioninterna,
          codpostal: delivery.codpostal,
          poblacion: delivery.poblacion,
          provinciaestado: delivery.provinciaestado,
          paisISO2: delivery.paisISO2,
          observacionesdireccion: delivery.observacionesdireccion,
          expanded: false
        };
      }

    });
  }
}
