import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ProviderService } from './../../../provider.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { HostListener, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, DoCheck } from '@angular/core';
import { MenuItems } from 'src/app/shared/menu-items/menu-items';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


/**
 * Componente lateral de la pantalla base.
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() showClass = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  mobileQuery: MediaQueryList;
  showMenu = '';
  parentIndex = 0;
  childIndex = 0;
  terceroName = '';

  esAgenciaLocal;

  tipoEntidad = null;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public globalInfo: ProviderService,
    private translate: TranslateService,
    private http: HttpClient,
    private route: Router
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.terceroName = localStorage.getItem('nombreCompleto');

    const userInfo = localStorage.getItem('userinfo');
    const parsed = JSON.parse(userInfo);
    if(parsed.esAgencia === "1") {
      this.tipoEntidad = 'AGENCIA'
    }


    const usuarioId = parsed.id;
    if(localStorage.getItem('adminId') == usuarioId) {
      //  this.menuItems.setUsuariosItem();
    }

    if(localStorage.getItem('esAgencia') == 'true'){
      // this.menuItems.addTransportistasItem();
      this.esAgenciaLocal = true;
      this.globalInfo.esAgencia = true;
      
    }



     

     

  }

  ngOnInit(){
    const userInfo = localStorage.getItem('userinfo');
    const parsed = JSON.parse(userInfo);

    const usuarioId = parsed.id;
    if(localStorage.getItem('adminId') == usuarioId) {
       this.menuItems.setUsuariosItem();
    }

    if(localStorage.getItem('esAgencia') == 'true'){
      this.menuItems.addTransportistasItem();
      this.esAgenciaLocal = true;
      this.globalInfo.esAgencia = true;
    }
  }



  ngAfterViewInit() {
    // console.log('llamamos peticion')
    // const userInfo = localStorage.getItem('userinfo');
    // const parsed = JSON.parse(userInfo);

    // const url = this.globalInfo.url + 'Tercero/' + 'recuperar';

    // const guid = parsed.guid;
    // const valores = {
    //   guid,
    // };

    // const body = {
    //   valores
    // };

    // const headers = new HttpHeaders()
    //   .set('Access-Control-Allow-Origin', '*')
    //   .set('Access-Control-Allow-Methods', 'GET, POST, PUT, PATCH, POST, DELETE, OPTIONS')
    //   .set('content-type', 'application/json')
    //   .set('accept', 'text/plain')
    //   .set('Authorization', this.globalInfo.token)
    //   .set('Referido', 'Web');

    // this.http.post<any>(url, body, { headers }).subscribe(async response => {
      
    //    console.log('Sesión iniciada.', response);
    //   this.terceroName = response.tercero[0].nombrecompleto;
    //   this.globalInfo.terceroId = response.tercero[0].terceroId;
    //   this.globalInfo.adminId = response.tercero[0].administradorId;

    //  const esAgenciaLocal = response.tercero[0].esAgencia;
     

   

    //   const usuarioId = parsed.id;
    //   if(this.globalInfo.adminId === usuarioId) {
    //     console.log('setUsuariosItem')
    //      this.menuItems.setUsuariosItem();
    //   }

    //   if(esAgenciaLocal == true){
    //     console.log('esAgencia')
    //      this.menuItems.addTransportistasItem();
    //   }

    //   // console.log(this.globalInfo.userInfo.esAgencia === 1)
      
    // });

    // window.onbeforeunload = () => this.ngOnDestroy();
  }

  @HostListener('window:unload')
  // @HostListener('window:onbeforeunload')
  ngOnDestroy(): void {
    this.terceroName = '';
    this.mobileQuery.removeListener(this._mobileQueryListener);
    // this.menuItems.deleteUsuariosItem();
    // console.log(this.menuItems.getMenuitem());
     console.log('destruido');
     
  }

  saveChanges() {
    const ruta = window.location.pathname

  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  handleNotify() {
    if(window.innerWidth < 1024){
      this.notify.emit(!this.showClass);
    }
  }

}
