import { CalendarComponent } from './sections/calendar/calendar.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutes, AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent, LogoutDialogContent } from './layouts/main-layout/header/header.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SidebarComponent } from './layouts/main-layout/sidebar/sidebar.component';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { DocumentService } from './services/document.service';
import { DatePipe, CommonModule } from '@angular/common';
import { CalendarModule, DateAdapter  } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import {NgChartsModule} from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AppNotFoundComponent } from './layouts/not-found/not-found.component';


// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AppBlankComponent,
    MainLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    AppNotFoundComponent,
    SpinnerComponent,
    LogoutDialogContent,
    // CalendarComponent
  ],
  entryComponents: [
    LogoutDialogContent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(AppRoutes),
    FlexLayoutModule,
    BrowserModule,
    IonicModule.forRoot(),
    FlatpickrModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatSortModule,
    MatButtonModule,
    MatDialogModule,
    SharedModule,
    GoogleMapsModule,
    NgChartsModule,
    NgApexchartsModule,
    RouterModule.forRoot(AppRoutes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  exports: [TranslateModule],
  providers: [{
    provide: {
      RouteReuseStrategy,
      TranslateService,
      DocumentService
    },
    useClass:
      IonicRouteStrategy

  },
  DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
