import { MenuItems } from 'src/app/shared/menu-items/menu-items';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProviderService } from 'src/app/provider.service';


/**
 * Componente de la barra superior de la pantalla base.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../../../assets/styles/header.scss'],
})
export class HeaderComponent implements OnDestroy {

  currentUser: string;
  currentNombreCompleto: string;

  public selectedLanguage: any = {
    language: 'Español',
    code: 'es',
    icon: 'es'
  };

  public languages: any[] = [{
    language: 'Catalán',
    code: 'es',
    type: 'ca',
    icon: 'va'
  },
  {
    language: 'Español',
    code: 'es',
    icon: 'es'
  }];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public globalInfo: ProviderService,
    private menuItems: MenuItems
  ) {
    //this.globalInfo.translate.setDefaultLang('en');
    const userInfo = localStorage.getItem('userinfo');
    const parsed = JSON.parse(userInfo);
    this.currentUser = parsed.usuario;
     this.currentNombreCompleto = parsed.nombreCompleto;

      // this.currentNombreCompleto = this.globalInfo.nombreCompleto;

  }

  // changeLanguage(lang: any): void {
  //   this.globalInfo.translate.use(lang.code);
  //   this.selectedLanguage = lang;
  // }

  ngOnDestroy(): void {
  }

  logout() {
    // this.router.navigate(['/acceso'], { relativeTo: this.route });
    window.localStorage.removeItem('userinfo');
    window.localStorage.removeItem('token');
    this.globalInfo.userInfo = null;
    this.menuItems.deleteUsuariosItem();
    // console.log('logout hecho', this.menuItems, this.globalInfo.userInfo)

  }

  openDialog(action: string): void {
    //obj.action = action;
    const dialogRef = this.dialog.open(LogoutDialogContent, {
      //data: obj
    });

    dialogRef.afterClosed().subscribe( result => {
     if(result.event == 'Cerrar') {
         this.logout();


      // console.log("Sesión cerrada");
     }
    });
  }
}

@Component({
  // tslint:disable-next-line - Disables all
  selector: 'logout-dialog-content',
  templateUrl: 'logout-dialog-content.html',
})
// tslint:disable-next-line - Disables all
export class LogoutDialogContent {
  // tslint:disable-next-line - Disables all
  local_data: any;

  constructor(public dialogRef: MatDialogRef<LogoutDialogContent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

    this.local_data = { ...data };
  }

  doAction(): void {
    this.dialogRef.close({ event: 'Cerrar' });
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
