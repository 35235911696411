import { HttpClient, HttpEvent, HttpErrorResponse, HttpHeaders, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ProviderService } from '../provider.service';
import { map, catchError } from 'rxjs/operators';
import { version } from 'typescript';

/**
 * Servicio para manipular la subida y descarga de documentos PDF
 */
@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private globalInfo: ProviderService,
    private httpClient: HttpClient
  ) { }

  /**
   * Sube un documento PDF a la base de datos
   * @param {File} fileToUpload Objeto File del documento a subir al servidor
   * @param {number} idDescarga Descarga a la que asociar el documento
   * @returns response
   */
  uploadDocument(fileToUpload: File, idDescarga: number): any {
   // const token = localStorage.getItem('token');
    const url = this.globalInfo.uploadFileUrl + 'upload';
    const formData: FormData = new FormData();

    const name = fileToUpload.name;
    const nameSplit = name.split('.');

    const docName = 'documento_documento_descargaId_' + idDescarga + '_INSERT_1_' + nameSplit[0];

    formData.append('doc', fileToUpload, docName);

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Methods', 'GET, POST, PUT, PATCH, POST, DELETE, OPTIONS')
      .set('accept', 'text/plain')
      .set('Authorization', this.globalInfo.token)

      .set('Referido', 'Web');

    return this.httpClient.post(url, formData, { headers, reportProgress: true, observe: 'events' }).pipe(
      map((event) => {
        switch(event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError(error => {
        let errorMsg: string;
        console.log('error', error);

        if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
        } else {
            errorMsg = this.getServerErrorMessage(error);
            console.log(errorMsg);
        }

        return throwError(errorMsg);
      })
    ).subscribe((event: any) => {
      if (typeof (event) === 'object') {
      }
    });
  }


  /**
   * Descarga un documento de la carpeta Temporal
   * @param {string} nombreDoc Nombre del documento a descargar
   * @returns El documento descargado
   */
  downloadDocument(nombreDoc): any {
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Methods','GET, POST, PUT, PATCH, POST, DELETE, OPTIONS')
      // .set('Content-type', 'application/pdf')
      .set('accept', 'text/plain')
      .set('Authorization', this.globalInfo.token)
      .set('Referido', 'Web');




    const url = this.globalInfo.url + 'Doc/Documento/' + nombreDoc ;

    //AZURE añadidos
    const valores = {
      nombreDoc,
      useragent: 'app',
      version: this.globalInfo.version
    };

    const body = {
      valores
    };

    return this.httpClient.get(url, {
      headers,
      responseType: 'blob' as 'json'
    });
  }


  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
        case 404: {
            return `Not Found: ${error.message}`;
        }
        case 403: {
            return `Access Denied: ${error.message}`;
        }
        case 443: {
          return `Access failed: ${error.message}`;
        }
        case 500: {
            return `Internal Server Error: ${error.message}`;
        }
        default: {
            return `Unknown Server Error: ${error.message}`;
        }

    }
}
}
