import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProviderService } from './../../provider.service';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private globalInfo: ProviderService,
    private http: HttpClient
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 500px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }


  /**
   * En el inicio del componente base recupera la información del tercero asignado al usuario actual.
   */
  ngOnInit() {
   // const token = localStorage.getItem('token');

    const url = this.globalInfo.url + 'Tercero/' + 'recuperar';
    const userInfo = localStorage.getItem('userinfo');
    const parsed = JSON.parse(userInfo);
    const guid = parsed.guid;

    const valores = {
      guid,
    };

    const body = {
      valores
    };

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Methods', 'GET, POST, PUT, PATCH, POST, DELETE, OPTIONS')
      .set('content-type', 'application/json')
      .set('accept', 'text/plain')
      .set('Authorization', this.globalInfo.token)
      .set('Referido', 'Web');

    this.http.post<any>(url, body, { headers }).subscribe(response => {
      this.globalInfo.tercero = response.tercero[0].nombrecompleto;
    });
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
  
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

}
