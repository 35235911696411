import { ProviderService } from 'src/app/provider.service';
import { Injectable } from "@angular/core";

export interface ChildrenItems {
  state: string;
  type?: string;
  name: string;
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    children?: ChildrenItems[];
}

export const users = { state: 'usuarios', type: 'link', name: 'Usuarios' };
export const transportistas = { state: 'transportistas', type: 'link', name: 'Transportistas' };

export let MENUITEMSPORDEFECTO = [
  // { state: 'dashboard', name: 'Dashboard', type: 'link' },
   { state: 'envios', type: 'link', name: 'Envíos'},
   { state: 'finalizados', type: 'link', name: 'Envíos finalizados'},
   { state: 'direcciones', type: 'link', name: 'Direcciones' },
   { state: 'calendario', type: 'link', name: 'Calendario' },
   {
     state: 'configuracion',
     type: 'link',
     name: 'Configuración'
   },
   {state: 'estadisticas', type: 'link', name: 'Estadísticas'}
];

export let MENUITEMS = [
   // { state: 'dashboard', name: 'Dashboard', type: 'link' },
    { state: 'envios', type: 'link', name: 'Envíos'},
    { state: 'finalizados', type: 'link', name: 'Envíos finalizados'},
    { state: 'direcciones', type: 'link', name: 'Direcciones' },
    { state: 'calendario', type: 'link', name: 'Calendario' },
    {
      state: 'configuracion',
      type: 'link',
      name: 'Configuración'
    },
    {state: 'estadisticas', type: 'link', name: 'Estadísticas'}
];


@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }


  setUsuariosItem() {
  //  console.log('setUsuariosItem');
    MENUITEMS.push(users);
  }

  addTransportistasItem() {
    //  console.log('setUsuariosItem');
      MENUITEMS.push(transportistas);
    }

  deleteUsuariosItem() {
    // MENUITEMS = MENUITEMSPORDEFECTO;
    MENUITEMS = MENUITEMS.filter(state => state.state != 'usuarios' && state.state != 'transportistas');
    // console.log('Poniendo items por defecto...');
  }
}
