import { PaginatorComponent } from './paginator/paginator.component';
import { SpinnerComponent } from './spinner.component';
import { NgModule } from '@angular/core';

import { MenuItems, MENUITEMS } from './menu-items/menu-items';
import { NavigatorAnchorDirective, NavigatorLinkDirective, NavigatorDirective } from './navigator/index';

@NgModule({
  declarations: [
    NavigatorAnchorDirective,
    NavigatorLinkDirective,
    NavigatorDirective,
    //SpinnerComponent,
    //PaginatorComponent,
  ],
  exports: [
    NavigatorAnchorDirective,
    NavigatorLinkDirective,
    NavigatorDirective,
    //SpinnerComponent,
    //PaginatorComponent
  ],
  providers: [ MenuItems ]
})
export class SharedModule { }
