import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import ls from 'localstorage-slim';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(translate:  TranslateService) {


    if(localStorage.getItem('idioma') == null){
      // console.log(localStorage.getItem('idioma'))
      translate.setDefaultLang('es');
      translate.use('es');
      localStorage.setItem('idioma','es');
      // console.log('Idioma actual', translate.currentLang)
      }
    else{
      // console.log(localStorage.getItem('idioma'))
      translate.use(localStorage.getItem('idioma'));
      // console.log('Idioma actual', translate.currentLang)
    }



  }

}
