import { AppNotFoundComponent } from './layouts/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'acceso',
    pathMatch: 'full'
  },
  {
    path: 'app',
    component: MainLayoutComponent,
    children: [
      {
        path: 'app',
        redirectTo: 'envios',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren:
          () => import('./sections/sections.module').then(m => m.SectionsModule)
      }
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: '',
        redirectTo: 'acceso',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren:
          () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
   {path: '**', component: AppNotFoundComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules, enableTracing: true, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
