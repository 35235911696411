import { Directive, HostListener, Inject } from '@angular/core';

import { NavigatorLinkDirective } from './navigatorlink.directive';

@Directive({
  selector: '[appNavigatorToggle]'
})
export class NavigatorAnchorDirective {
  protected navlink: NavigatorLinkDirective;

  constructor(@Inject(NavigatorLinkDirective) navlink: NavigatorLinkDirective) {
    this.navlink = navlink;
  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    this.navlink.toggle();
  }
}
